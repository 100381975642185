import React, { useEffect, useState, useRef } from 'react'
import PedidoItem from './PedidoItem'
import BackendService from '../../integrations/BackendService';
import { GiSoundOn, GiSoundOff } from "react-icons/gi";
import { BsPrinter, BsPrinterFill } from 'react-icons/bs';

export default function PedidosNovos({ onPedidoItemClick, handleImprimirSozinho, isAutoPrintEnabled, setIsAutoPrintEnabled, pedidos, refetch }) {
  const backendService = new BackendService();
  const [isUserInteracted, setIsUserInteracted] = useState(false); // Estado para rastrear interação do usuário
  const audioRef = useRef(null);
  const [abaAtiva, setAbaAtiva] = useState('paraAprovar');

  const handleAutoPrintToggle = () => {
    setIsAutoPrintEnabled(!isAutoPrintEnabled); // Inverte o valor do toggle de impressão automática
    if(!isAutoPrintEnabled && pedidosParaAprovar.length > 0){
      handleImprimirSozinho(pedidosParaAprovar)
    }
  };

  const pedidosAprovados = pedidos?.filter(pedido => pedido.statusPedido === 'EM_PREPARO') || [];
  const pedidosParaAprovar = pedidos?.filter(pedido => pedido.statusPedido === 'AGUARDANDO_CONFIRMACAO') || [];
  const pedidosDespachados = pedidos?.filter(pedido => pedido.statusPedido === 'DESPACHADO') || []; // [] garante que fetchData será chamado apenas uma vez, na montagem do componente.
  const pedidosAguardandoRetirada = pedidos?.filter(pedido => pedido.statusPedido === 'AGUARDANDO_RETIRADA') || [];
  const previousPedidosParaAprovarRef = useRef(null);

  const handleUserInteraction = () => {
    setIsUserInteracted(!isUserInteracted);
  };

  const playSound = () => {
    if (isUserInteracted && audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error('Erro ao reproduzir o som:', error);
      });
    }

  };

  useEffect(() => {
    const verificarPedidos = async () => {
      if (pedidosParaAprovar.length > 0) {
        playSound(); // Toca o som se houver pedidos para aprovar
        if (isAutoPrintEnabled) {
          await handleImprimirSozinho(pedidosParaAprovar); // Aguarda a finalização
          refetch(); // Faz o refetch após a impressão
        }
      }
    };
  
    // Configura o intervalo para executar a função a cada 3 minutos
    const intervalId = setInterval(() => {
      verificarPedidos();
    }, 30000); // 180000 ms = 3 minutos
  
    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, [pedidosParaAprovar]);

  useEffect(() => {
    const connectEventSource = () => {
      const baseURL = backendService.api.defaults.baseURL;
      const eventSource = new EventSource(`${baseURL}events/${localStorage.getItem('restaurante')}`);

      eventSource.onmessage = (event) => {
        console.log('Evento SSE recebido:', event.data);
        refetch(); // Chama refetch para atualizar os dados
      };

      eventSource.onerror = (error) => {
        console.error('Erro na conexão SSE:', error);
        eventSource.close();

        setTimeout(() => {
          connectEventSource();
          refetch(); // Sincroniza dados após reconectar
        }, 1000); // Intervalo reduzido para 1 segundo
      };

      return eventSource;
    };

    const eventSource = connectEventSource();

    // Cleanup para fechar a conexão SSE ao desmontar o componente
    return () => {
      eventSource.close();
      console.log('Conexão SSE fechada');
    };
  }, [refetch]); // Certifique-se de que o `refetch` está incluído nas dependências

  useEffect(() => {
    const processarPedidos = async () => {
      if (pedidosParaAprovar.length > previousPedidosParaAprovarRef.current) {
        playSound(); // Toca o som
        if(isAutoPrintEnabled){
          await handleImprimirSozinho(pedidosParaAprovar); // Aguarda a finalização
          refetch();
        }
      }
      previousPedidosParaAprovarRef.current = pedidosParaAprovar.length;
    };

    processarPedidos();
  }, [pedidosParaAprovar]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsUserInteracted(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleChangeAba = (aba) => {
    setAbaAtiva(aba);
    setIsAutoPrintEnabled(false)
    onPedidoItemClick(null)
  };
  return (
    <div className='flex flex-col justify-between bg-white shadow-md rounded-md min-w-[35rem] mr-5'>
      <audio ref={audioRef} src='/notificacao.mp3' preload="auto" />
      <div className="flex">
        <div className="relative">
          <button
            className={`p-2 rounded-t-lg h-full w-full py-4 ${abaAtiva === 'paraAprovar' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
            onClick={() => handleChangeAba('paraAprovar')}
          >
            Pedidos para Aprovar
          </button>
          {pedidosParaAprovar.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-6 h-6 flex items-center justify-center">
              {pedidosParaAprovar.length}
            </span>
          )}
        </div>

        <div className="relative">
          <button
            className={`p-2 rounded-t-lg h-full w-full py-4 ${abaAtiva === 'aprovados' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
            onClick={() => handleChangeAba('aprovados')}
          >
            Pedidos em Preparo
          </button>
          {pedidosAprovados.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-6 h-6 flex items-center justify-center">
              {pedidosAprovados.length}
            </span>
          )}
        </div>

        <div className="relative">
          <button
            className={`p-2 rounded-t-lg h-full w-full py-4 ${abaAtiva === 'despachados' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
            onClick={() => handleChangeAba('despachados')}
          >
            Pedidos Despachados
          </button>
          {pedidosDespachados.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-6 h-6 flex items-center justify-center">
              {pedidosDespachados.length}
            </span>
          )}
        </div>

        <div className="relative">
          <button
            className={`p-2 rounded-t-lg w-full py-4 ${abaAtiva === 'aguardando_retirada' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
            onClick={() => handleChangeAba('aguardando_retirada')}
          >
            Pedidos Aguardando Retirada
          </button>
          {pedidosAguardandoRetirada.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-6 h-6 flex items-center justify-center">
              {pedidosAguardandoRetirada.length}
            </span>
          )}
        </div>

      </div>
      <div className='flex-1 overflow-y-auto px-2 bg-slate-100'>
        {/* Lista de Pedidos */}
        {abaAtiva === 'paraAprovar' ? (
          pedidosParaAprovar?.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))
        ) : abaAtiva === 'aprovados' ? (
          pedidosAprovados?.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))
        ) : abaAtiva === 'despachados' ? (
          pedidosDespachados?.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))) :
          pedidosAguardandoRetirada?.map((pedido, index) => (
            <PedidoItem key={index} pedido={pedido} onClick={() => onPedidoItemClick(pedido)} />
          ))}
      </div>

      <div className='flex flex-col p-2 gap-2 '>
        <div className='flex items-center'>
          <button
            className={`left-2 p-2 rounded-full shadow-lg ${isUserInteracted ? 'bg-green-500' : 'bg-red-500'
              } text-white font-bold`}
            onClick={handleUserInteraction}
          >
            {isUserInteracted ? <GiSoundOn size={24} /> : <GiSoundOff size={24} />}
          </button>
          <span className='text-center ml-2'>
            {isUserInteracted ? 'Som: Ativado' : 'Som: Desativado'}
          </span>
        </div>
        {/* inicialmente não vou implementar a funcionalidade de imprimir sozinho, é necessário realizar configurações do lado do navegador do cliente. */}
        {/* <div className='flex items-center'>


          <button
            className={`left-2 p-2 rounded-full shadow-lg ${isAutoPrintEnabled ? 'bg-green-500' : 'bg-red-500'} text-white font-bold`}
            onClick={handleAutoPrintToggle}
          >
            {isAutoPrintEnabled ? <BsPrinterFill size={24} /> : <BsPrinter size={24} />}
          </button>

          <span className='text-center ml-2'>
            {isAutoPrintEnabled ? 'Impressão Automática: Ativada' : 'Impressão Automática: Desativada'}
          </span>
        </div> */}



      </div>
    </div>

  )
}
