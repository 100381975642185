import React from 'react'

export default function PedidoItem({ pedido, onClick }) {

  const handleClick = () => {
    // Chama a função de retorno de chamada (callback) e passa o pedido como argumento
    onClick(pedido);
  };


  return (
    <button className='flex flex-col my-3 rounded-md w-full min-h-[6rem] p-2 bg-white drop-shadow-lg hover:bg-blue-100 text-left' onClick={handleClick}>
      <span>#{pedido.id} - <span className='font-bold'>{pedido.tipoDeEncomenda}</span></span>
      {pedido.tipoDeEncomenda === 'DELIVERY' ? (<span>
        <span>Endereço: </span>
        {pedido.endereco.logradouro ? `${pedido.endereco.logradouro}` : ''}
        {pedido.endereco.numero ? `, ${pedido.endereco.numero}` : ''}
        {pedido.endereco.bairro ? `, ${pedido.endereco.bairro}` : ''}
        {pedido.endereco.complemento ? ` - ${pedido.endereco.complemento}` : ''}
      </span>) : (<span>Nome do cliente: {pedido.nomeCliente}</span>)}
      
      <span>Valor: R$ {pedido.valorTotal.toFixed(2)}</span>
    </button>
  )
}
