import React, { useState, useEffect } from 'react';
import BackendService from '../integrations/BackendService';
import { useQuery } from '@tanstack/react-query';

export default function HistoricoPedidos() {
  const backendService = new BackendService();
  const [pedidoSelecionado, setPedidoSelecionado] = useState(null);
  const [filtroStatus, setFiltroStatus] = useState('TODOS');
  const [filtroDataInicial, setFiltroDataInicial] = useState('');
  const [filtroDataFinal, setFiltroDataFinal] = useState('');
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [itensPorPagina] = useState(10); // Quantidade de itens por página

  const { data: pedidos, isLoading, isError, error } = useQuery({
    queryKey: ['pedidosRestauranteHistorico'],
    queryFn: async () => {
      const response = await backendService.buscarPedidosRestauranteHistorico();
      return response;
    },
  });

  const handleDetalhesPedido = (pedido) => {
    setPedidoSelecionado(pedido);
  };

  const fecharDetalhesPedido = () => {
    setPedidoSelecionado(null);
  };

  const handleFiltroStatus = (status) => {
    setFiltroStatus(status);
  };

  const handleFiltroDataInicial = (e) => {
    setFiltroDataInicial(e.target.value);
  };

  const handleFiltroDataFinal = (e) => {
    setFiltroDataFinal(e.target.value);
  };

  const getClassFromStatus = (statusPedido) => {
    let className = ''; // classe padrão caso nenhum dos casos abaixo se aplique

    switch (statusPedido) {
      case 'CANCELADO':
        className = 'bg-red-500 font-bold py-1 px-2 rounded-lg';
        break;
      case 'EM_PREPARO':
        className = 'bg-yellow-500 font-bold py-1 px-2 rounded-lg'; // por exemplo, cor amarela
        break;
      case 'AGUARDANDO_CONFIRMACAO':
        className = 'bg-blue-500 font-bold py-1 px-2 rounded-lg'; // por exemplo, cor azul
        break;
      case 'ENTREGUE':
        className = 'bg-green-300 font-bold py-1 px-2 rounded-lg'; // por exemplo, cor azul
        break;
      default:
        className = 'bg-fuchsia-300 font-bold py-1 px-2 rounded-lg'; // classe padrão para outros status
        break;
    }

    return className;
  }

  const formatarDataHora = (dataHora) => {
    const data = new Date(dataHora);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const segundos = data.getSeconds().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  };

  // Filtra os pedidos com base no status e no intervalo de datas selecionado
  const pedidosFiltrados = pedidos?.filter(pedido => {
    // Filtra por status
    if (filtroStatus !== 'TODOS' && pedido.statusPedido !== filtroStatus) {
      return false;
    }

    // Filtra por intervalo de datas
    if (filtroDataInicial && pedido.dataHoraPedido < filtroDataInicial) {
      return false;
    }

    if (filtroDataFinal && pedido.dataHoraPedido > filtroDataFinal) {
      return false;
    }

    return true;
  });

  // Paginação
  const indexOfLastPedido = paginaAtual * itensPorPagina;
  const indexOfFirstPedido = indexOfLastPedido - itensPorPagina;
  const pedidosPaginados = pedidosFiltrados?.slice(indexOfFirstPedido, indexOfLastPedido);

  const totalPaginas = Math.ceil(pedidosFiltrados?.length / itensPorPagina);

  return (
    <div className="flex flex-col h-screen p-4">
      <h1 className="text-2xl font-semibold mb-6">Histórico de Pedidos</h1>
      <div className='flex flex-col overflow-y-auto h-screen '>
        {/* Filtros */}
        <div className="mb-4 flex flex-col md:flex-row gap-4">
          <div className="flex items-center">
            <label className="mr-2">Filtrar por Status:</label>
            <select
              className="border rounded-lg py-2 px-3"
              value={filtroStatus}
              onChange={(e) => handleFiltroStatus(e.target.value)}
            >
              <option value="TODOS">Todos</option>
              <option value="EM_PREPARO">Em Preparo</option>
              <option value="CANCELADO">Cancelado</option>
              <option value="AGUARDANDO_CONFIRMACAO">Aguardando Confirmação</option>
              <option value="ENTREGUE">Entregue</option>
            </select>
          </div>

          <div className="flex items-center">
            <label className="mr-2">Filtrar por Data:</label>
            <div className="flex gap-2">
              <input
                type="date"
                className="border rounded-lg py-2 px-3"
                value={filtroDataInicial}
                onChange={handleFiltroDataInicial}
              />
              <span className="mx-2">até</span>
              <input
                type="date"
                className="border rounded-lg py-2 px-3"
                value={filtroDataFinal}
                onChange={handleFiltroDataFinal}
              />
            </div>
          </div>
        </div>

        {/* Tabela de Pedidos */}
        <div className="overflow-x-auto">
          <table className="table-auto min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4">Data e Hora</th>
                <th className="py-2 px-4">Valor</th>
                <th className="py-2 px-4">Status</th>
                <th className="py-2 px-4">Motivo Cancelamento</th>
                <th className="py-2 px-4">Ações</th>
              </tr>
            </thead>
            <tbody>
              {pedidosPaginados?.map(pedido => (
                <tr key={pedido.id} className="border-t">
                  <td className="py-2 px-4 text-center">
                    {formatarDataHora(pedido.dataHoraPedido)}
                  </td>
                  <td className="py-2 px-4 text-center">R$ {pedido.valorTotal.toFixed(2)}</td>
                  <td className="py-2 px-4 text-center">
                    <span className={getClassFromStatus(pedido.statusPedido)}>
                      {pedido.statusPedido}
                    </span>
                  </td>
                  <td className="py-2 px-4 text-center">{pedido.motivoCancelamento}</td>
                  <td className="py-2 px-4 text-center">
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onClick={() => handleDetalhesPedido(pedido)}
                    >
                      Detalhes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Navegação de Páginas */}
        <div className="flex justify-between mt-4">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={() => setPaginaAtual(paginaAtual - 1)}
            disabled={paginaAtual === 1}
          >
            Anterior
          </button>
          <div>
            Página {paginaAtual} de {totalPaginas}
          </div>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={() => setPaginaAtual(paginaAtual + 1)}
            disabled={paginaAtual === totalPaginas}
          >
            Próxima
          </button>
        </div>

        {/* Modal de Detalhes do Pedido */}
        {pedidoSelecionado && (
          <PedidoDetalhes pedido={pedidoSelecionado} onClose={fecharDetalhesPedido} />
        )}
      </div>
    </div>

  );
};

const PedidoDetalhes = ({ pedido, onClose }) => {

  const formatarDataHora = (dataHora) => {
    const data = new Date(dataHora);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const segundos = data.getSeconds().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  };
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-4">Detalhes do Pedido</h2>
        <div>Data e Hora: {formatarDataHora(pedido.dataHoraPedido)}</div>
        <div>Valor: R$ {pedido.valorTotal.toFixed(2)}</div>
        <div>Status: {pedido.statusPedido}</div>
        <div>Motivo cancelamento: {pedido.motivoCancelamento}</div>

        <h3 className="text-lg font-semibold mt-4">Itens do Pedido:</h3>
        <ul className="divide-y divide-gray-200">
          {pedido.itensPedido.map(item => (
            <li key={item.id} className="py-2">
              <div className="text-sm">{item.nome}</div>
              <div>Quantidade: {item.quantidade}</div>
              {item.observacao && (
                <div className="italic text-gray-500">Observação: {item.observacao}</div>
              )}
              {item.adicional && (
                <div className="italic text-gray-500">
                  {item.adicional.includes('#')
                    ? item.adicional.split('#').map((adicional, index) => (
                      <p key={index}>{adicional.trim()}</p>
                    ))
                    : <p>{item.adicional}</p>}
                </div>
              )}
              <div>Preço Unitário: R$ {item.precoUnitario.toFixed(2)}</div>
              {item.quantidade > 1 && (
                <div>Preço Total: R$ {item.precoTotal.toFixed(2)}</div>
              )}


            </li>
          ))}
        </ul>

        <button className="mt-6 bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300" onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
}