import React, { useState } from "react";
import BackendService from "../../integrations/BackendService";
import { BsTrash, BsPencil } from "react-icons/bs";
import { CurrencyInput, Currencies, Locales } from "input-currency-react";
import { v4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage";
import { imageDb } from "../../integrations/FirebaseConfig";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import CategoriaAdicional from "../Categoria Adicional/CategoriaAdicional";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField } from '@mui/material';

export default function Item({
	prato,
	onExcluir,
	listaAdicionais,
	idRestaurante,
}) {
	const backendService = new BackendService();
	const [modalAberto, setModalAberto] = useState(false);
	const [modalEditarAberto, setModalEditarAberto] = useState(false);
	const [itemEditando, setItemEditando] = useState(prato);
	const [isActive, setIsActive] = useState(prato.ativo);
	const [isHighlighted, setIsHighlighted] = useState(prato.destaque);
	const adicionarCategoria = () => {
		setCategoriasAdicionais([...categoriasAdicionais, {}]);
	};
	const [categoriasAdicionais, setCategoriasAdicionais] = useState(prato.categoriaAdicionais);

	const handleToggleActive = async () => {
		try {
			if (isActive) {
				Swal.fire({
					title: "Inativando...",
					text: "Estamos inativando seu produto.",
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				await backendService.desativarPrato(prato.id);
			} else {
				Swal.fire({
					title: "Ativando...",
					text: "Estamos ativando seu produto.",
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				await backendService.ativarPrato(prato.id);
			}
			Swal.close();
			setIsActive(!isActive);
		} catch (error) {
			Swal.close();
			console.error(error.message);
		}
	};



	const handleToggleHighlighted = async () => {
		try {
			if (isHighlighted) {
				Swal.fire({
					title: "Removendo destaque...",
					text: "Estamos removendo o destaque do seu produto.",
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				await backendService.removerDestacarPrato(prato.id);
			} else {
				Swal.fire({
					title: "Destacando...",
					text: "Estamos destacando seu produto.",
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				await backendService.destacarPrato(prato.id);
			}
			setIsHighlighted(!isHighlighted);
			Swal.close();
		} catch (error) {
			Swal.close();
			console.error(error.message);
		}
	};



	const handleExcluirPrato = async () => {
		try {
			Swal.fire({
				title: "Excluindo...",
				text: "Estamos removendo o item do cardápio.",
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			await backendService.deletarPrato(prato.id);
			Swal.close();
			setModalAberto(false);
			onExcluir(prato.id);
			// Aqui você pode adicionar lógica adicional após a exclusão, como atualizar a lista de pratos
		} catch (error) {
			Swal.fire({
				title: "Erro!",
				text: "Houve um erro ao tentar remover o item do cardápio.",
				icon: "error",
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.close();
					setModalAberto(false);
				}
			});
			console.error(error.message);
		} finally {
		}
	};

	const handleChangePreco = (campo, valor) => {
		valor = valor
			.replace(/\s/g, "")
			.replace("R$", "")
			.replace(".", "")
			.replace(",", ".");
		setItemEditando({
			...itemEditando,
			["preco"]: parseFloat(valor),
		});
	};

	const handleChangeNovoItemItemEditando = (campo, valor) => {
		setItemEditando({
			...itemEditando,
			[campo]: valor,
		});
	};

	const handleAbrirModalEditar = (item) => {
		setItemEditando(item);
		setModalEditarAberto(true);
	};

	const handleFecharModalEditar = () => {
		setModalEditarAberto(false);
		setCategoriasAdicionais(prato.categoriaAdicionais)
	};

	const handleSalvarEdicao = async () => {
		let categoriasAdicionaisValidadas = categoriasAdicionais;

		if (categoriasAdicionais.length > 0) {
			categoriasAdicionaisValidadas = categoriasAdicionais.filter(categoria =>
				categoria.adicionais?.length > 0 &&
				categoria.nome &&
				categoria.quantidadeMaxima &&
				(categoria.quantidadeMinima || categoria.quantidadeMinima === 0)
			);

			// Se não houver categorias válidas, mostre uma mensagem e saia da função
			if (categoriasAdicionaisValidadas.length !== categoriasAdicionais.length) {
				Swal.fire({
					title: "Erro!",
					text: "Existe categoria de adicional incompleta.",
					icon: "info",
					customClass: {
						container: 'swal2-container', // Classe personalizada aplicada aqui
					},
				});
				return;
			}

			// Verificar se algum valor máximo é menor que o valor mínimo
			const categoriaInvalida = categoriasAdicionaisValidadas.find(categoria =>
				categoria.quantidadeMaxima < categoria.quantidadeMinima
			);

			if (categoriaInvalida) {
				Swal.fire({
					title: "Erro!",
					text: "Uma ou mais categorias têm valor máximo menor que o valor mínimo.",
					icon: "info",
					customClass: {
						container: 'swal2-container', // Classe personalizada aplicada aqui
					},
				});
				return;
			}
		}
		setItemEditando({
			...itemEditando,
		});
		const itemParaSalvar = {
			...itemEditando,
			preco: itemEditando.preco.toFixed(2).toString(),
			idImagem: itemEditando.idImagem,
			categoriaAdicionalList: categoriasAdicionais
		};
		try {
			Swal.fire({
				title: "Editando...",
				text: "Estamos editando seu produto.",
				allowOutsideClick: false,
				customClass: {
					container: 'swal2-container', // Classe personalizada aplicada aqui
				},
				didOpen: () => {
					Swal.showLoading();
				},
			});
			await backendService.editarPrato(itemParaSalvar);
			Swal.close();
			window.location.reload();
		} catch (error) {
			Swal.close();
			console.error(error.message);
		}
	};

	const handleAlterarImagem = async (imagem) => {
		const uuid = v4();
		const imgRef = ref(imageDb, `${idRestaurante}/imagemProdutos/${uuid}`);
		Swal.fire({
			title: "Carregando...",
			text: "Alterando imagem.",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		await uploadBytes(imgRef, imagem);
		Swal.close();
		setItemEditando({
			...itemEditando,
			idImagem: uuid,
			urlImagem: imagem,
		});
	};

	const abrirModal = () => setModalAberto(true);
	const fecharModal = () => setModalAberto(false);
	return (
		<div className="flex items-center w-full justify-between p-2 border-t mb-2 ">
			<div className="flex flex-col md:flex-row items-start md:items-center w-full ">
				<div className="flex items-center justify-center w-[120px] h-[100px] overflow-hidden rounded-md bg-white">
					<img
						src={prato.urlImagem}
						alt="Descrição da imagem"
						className="h-full w-auto object-cover"
					/>
				</div>


				<div className="flex flex-col md:flex-row items-start md:items-center mx-3 w-full ">
					<div className="flex flex-col items-start w-3/12">
						<span className="font-semibold">Preço</span>
						<div className="flex items-center">
							<span className="ml-1">
								{prato.categoriaAdicionais.some(categoria => categoria.precoAPartir)
									? `A partir de R$ ${prato.categoriaAdicionais
										.filter(categoria => categoria.precoAPartir)
										.flatMap(categoria => categoria.adicionais)
										.reduce((min, adicional) => adicional.preco < min ? adicional.preco : min, Infinity)
										.toFixed(2)}`
									: `R$ ${prato.preco.toFixed(2)}`}
							</span>
						</div>
					</div>


					<div className="flex flex-col w-full mx-4 ">
						<span className="font-semibold">{prato.nome}</span>
						<div>
							{prato.descricao.length > 180 ? (
								<span className="text-sm text-gray-500 text-justify">
									{prato.descricao.slice(0, 180)}...
								</span>
							) : (
								<span className="text-sm text-gray-500 text-justify">
									{prato.descricao}
								</span>
							)}
						</div>
					</div>

					{prato.categoriaAdicionais?.length > 0 && (
						<div className="flex flex-col w-fit">
							<span className="font-semibold">
								Grupos Adicionais:
							</span>
							<ul className="list-disc list-inside">
								{prato?.categoriaAdicionais?.map((adicional, index) => (
									<li
										key={index}
										className="text-sm text-gray-500 flex justify-between"
									>
										<span style={{ whiteSpace: "nowrap" }}>
											- {adicional.nome}
										</span>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
			<div className="flex ">
				<div className="flex flex-col items-start">
					<div className="flex gap-2 justify-center items-center">
						<Switch
							checked={isActive}
							onChange={handleToggleActive}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<span>Ativo</span>
					</div>
					<div className="flex gap-2 justify-center items-center">
						<Switch
							checked={isHighlighted}
							onChange={handleToggleHighlighted}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<span>Destacado</span>
					</div>
				</div>
				<div className="flex flex-col justify-between ml-4 ">
					<button
						onClick={() => handleAbrirModalEditar(prato)}
					>
						<BsPencil size={20} />
					</button>
					<button>
						<BsTrash size={20} onClick={abrirModal} />
					</button>
				</div>
			</div>
			{modalAberto && (
				<div className="modal fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
					<div className="bg-white p-4 rounded shadow-lg">
						<h2 className="text-lg font-semibold mb-4">
							Confirmar Exclusão
						</h2>
						<p>Tem certeza que deseja excluir este item?</p>
						<div className="mt-4 flex justify-end">
							<button
								className="px-4 py-2 mr-2 bg-gray-300 rounded"
								onClick={fecharModal}
							>
								Cancelar
							</button>
							<button
								className="px-4 py-2 bg-red-600 text-white rounded"
								onClick={handleExcluirPrato}
							>
								Excluir
							</button>
						</div>
					</div>
				</div>
			)}

			<Dialog
				open={modalEditarAberto}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						handleFecharModalEditar();
					}
				}}
				maxWidth="md"
				fullWidth
			>
				<DialogTitle>Editar Produto</DialogTitle>
				<DialogContent dividers>
					<div className="flex gap-4">
						<div className="relative flex items-center justify-center w-[240px] h-[200px] flex-shrink-0">
							{/^\s*https:\/\//.test(itemEditando?.urlImagem) ? (
								<img
									src={itemEditando.urlImagem}
									alt="Imagem selecionada"
									className="w-auto h-full object-cover rounded-md"
								/>
							) : (
								itemEditando?.urlImagem instanceof Blob || itemEditando?.urlImagem instanceof File ? (
									<img
										src={URL.createObjectURL(itemEditando.urlImagem)}
										alt="Imagem selecionada"
										className="w-auto h-full object-cover rounded-md"
									/>
								) : (
									<div className="flex p-6 items-center justify-center w-full h-full border-2 border-dashed rounded-md bg-gray-100">
										<span className="text-gray-500 text-center">Clique aqui para selecionar uma imagem</span>
									</div>
								)
							)}
							<input
								type="file"
								onChange={(e) => handleAlterarImagem(e.target.files[0])}
								className="absolute inset-0 opacity-0 cursor-pointer"
							/>
						</div>
						<div className="flex flex-col justify-between flex-grow gap-4">
							<TextField
								label="Nome"
								value={itemEditando.nome}
								onChange={(e) =>
									handleChangeNovoItemItemEditando("nome", e.target.value)
								}
								fullWidth
								margin="normal"
							/>
							<TextField
								label="Descrição"
								value={itemEditando.descricao}
								onChange={(e) =>
									handleChangeNovoItemItemEditando("descricao", e.target.value)
								}
								fullWidth
								margin="normal"
							/>

							<CurrencyInput
								options={{
									precision: 2,
									style: "currency",
									locale: "pt-BR",
									i18nCurrency: "BRL",
								}}
								placeholder="Preço"
								value={itemEditando.preco.toFixed(2)}
								onChangeEvent={handleChangePreco}
								className="w-full px-3 py-2 border rounded-lg"
							/>
						</div>
					</div>
					<div className="mt-4">
						<Button
							variant="contained"
							color="primary"
							onClick={adicionarCategoria}
						>
							+ Grupo adicional
						</Button>
						{categoriasAdicionais.map((categoria, index) => (
							<CategoriaAdicional
								key={index}
								index={index}
								categoriasAdicionais={categoriasAdicionais}
								setCategoriasAdicionais={setCategoriasAdicionais}
								listaAdicionais={listaAdicionais}
							/>
						))}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleFecharModalEditar} color="primary">
						Fechar
					</Button>
					<Button
						onClick={handleSalvarEdicao}
						color="primary"
						variant="contained"
					>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>

		</div>
	);
}
