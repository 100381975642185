import React, { useState, useEffect, useRef } from 'react';
import BackendService from '../integrations/BackendService';
import Swal from 'sweetalert2';
import { v4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage"
import { imageDb } from '../integrations/FirebaseConfig';
import {
    CurrencyInput,
    Currencies,
    Locales
} from 'input-currency-react';



export default function ConfiguracaoPage() {
    const backendService = new BackendService();
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const [config, setConfig] = useState({
        id: null,
        nome: '',
        cnpj: '',
        endereco: {
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            cep: ''
        },
        configuracaoRestaurante: {
            idLogo: '',
            urlLogo: '',
            categoria: '',
            valorFrete: '',
            aberto: false,
            horariosFuncionamento: [],
            delivery: false,
            retirada: false,
            tempoMinimoEntrega: '',
            tempoMaximoEntrega: '',
            tempoMinimoPreparo: '',
            tempoMaximoPreparo: ''
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await backendService.buscarConfiguracaoRestaurante();
                const valorFrete = parseFloat(response.configuracaoRestaurante.valorFrete).toFixed(2);

                const configResponse = {
                    ...response,
                    configuracaoRestaurante: {
                        ...response.configuracaoRestaurante,
                        valorFrete: valorFrete
                    }
                };

                setConfig(configResponse);
            } catch (error) {
                console.error('Erro ao buscar configuração:', error.message);
            }
        };
        fetchData();
    }, []);

    const diasDaSemanaMapeados = {
        SEGUNDA: 'segunda-feira',
        TERCA: 'terça-feira',
        QUARTA: 'quarta-feira',
        QUINTA: 'quinta-feira',
        SEXTA: 'sexta-feira',
        SABADO: 'sábado',
        DOMINGO: 'domingo'
    };

    const diasDaSemana = ['SEGUNDA', 'TERCA', 'QUARTA', 'QUINTA', 'SEXTA', 'SABADO', 'DOMINGO'];

    const handleChangeConfiguracao = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevState => ({
            ...prevState,
            configuracaoRestaurante: {
                ...prevState.configuracaoRestaurante,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    };

    const handleSubmit = async () => {
        if ((!config.configuracaoRestaurante.valorFrete || isNaN(config.configuracaoRestaurante.valorFrete) || parseFloat(config.configuracaoRestaurante.valorFrete) === 0) && config.configuracaoRestaurante.delivery) {
            Swal.fire({
                text: "É necessário ter um valor do frete.",
                icon: "info"
            });
            return;
        }

        if (config.configuracaoRestaurante.delivery && (!config.configuracaoRestaurante.tempoMaximoEntrega || isNaN(config.configuracaoRestaurante.tempoMaximoEntrega) || parseFloat(config.configuracaoRestaurante.tempoMaximoEntrega) === 0)) {
            Swal.fire({
                text: "É necessário ter um tempo maximo de entrega para o delivery.",
                icon: "info"
            });
            return;
        }

        if (config.configuracaoRestaurante.delivery && (!config.configuracaoRestaurante.tempoMinimoEntrega || isNaN(config.configuracaoRestaurante.tempoMinimoEntrega) || parseFloat(config.configuracaoRestaurante.tempoMinimoEntrega) === 0)) {
            Swal.fire({
                text: "É necessário ter um tempo minimo de entrega para o delivery.",
                icon: "info"
            });
            return;
        }

        if (config.configuracaoRestaurante.retirada && (!config.configuracaoRestaurante.tempoMaximoPreparo || isNaN(config.configuracaoRestaurante.tempoMaximoPreparo) || parseFloat(config.configuracaoRestaurante.tempoMaximoPreparo) === 0)) {
            Swal.fire({
                text: "É necessário ter um tempo maximo de preparo para a retirada.",
                icon: "info"
            });
            return;
        }

        if (config.configuracaoRestaurante.retirada && (!config.configuracaoRestaurante.tempoMinimoPreparo || isNaN(config.configuracaoRestaurante.tempoMinimoPreparo) || parseFloat(config.configuracaoRestaurante.tempoMinimoPreparo) === 0)) {
            Swal.fire({
                text: "É necessário ter um tempo minimo de preparo para a retirada.",
                icon: "info"
            });
            return;
        }

        if (config.configuracaoRestaurante.delivery && config.configuracaoRestaurante.tempoMinimoEntrega >= config.configuracaoRestaurante.tempoMaximoEntrega) {
            Swal.fire({
                text: "O tempo mínimo de entrega deve ser menor que o tempo máximo.",
                icon: "info"
            });
            return;
        }

        if (config.configuracaoRestaurante.retirada && config.configuracaoRestaurante.tempoMinimoPreparo >= config.configuracaoRestaurante.tempoMaximoPreparo) {
            Swal.fire({
                text: "O tempo mínimo de preparo deve ser menor que o tempo máximo.",
                icon: "info"
            });
            return;
        }

        if (!config.configuracaoRestaurante.delivery && !config.configuracaoRestaurante.retirada) {
            Swal.fire({
                text: "O restaurante precisa ter pelo menos uma modalidade de encomenda.",
                icon: "info"
            });
            return;
        }

        Swal.fire({
            title: 'Salvando...',
            text: 'Por favor, aguarde enquanto salvamos sua alteração.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const uuid = v4();
        const isNewImage = config.configuracaoRestaurante.urlLogo instanceof Blob || config.configuracaoRestaurante.urlLogo instanceof File

        if (isNewImage) {
            const imgRef = ref(imageDb, `${config.id}/logo/${uuid}`);
            await uploadBytes(imgRef, config.configuracaoRestaurante.urlLogo); // Ensure this is the correct file reference

            setConfig(prevState => ({
                ...prevState,
                configuracaoRestaurante: {
                    ...prevState.configuracaoRestaurante,
                    idLogo: uuid // or imgRef.fullPath if you want the full path
                }
            }));
        }

        const { urlLogo, ...rest } = config.configuracaoRestaurante;

        const configuracaoParaEditar = {
            ...config,
            configuracaoRestaurante: {
                ...rest,
                idLogo: isNewImage ? uuid : config.configuracaoRestaurante.idLogo
            }
        };

        salvarEdicaoConfiguracaoRestaurante(configuracaoParaEditar)
    };

    const salvarEdicaoConfiguracaoRestaurante = async (config) => {
        try {
            await backendService.salvarConfiguracaoRestaurante(config.configuracaoRestaurante);
            Swal.fire(
                'Configuração alterada!',
                'A configuração do seu restaurante foi alterada.',
                'success'
            );
            window.location.reload();
        } catch (error) {
            console.error(error.message);
            Swal.fire(
                'Erro!',
                'Não foi possível alterar a configuração do seu restaurante.',
                'error'
            );
            console.error('Erro ao editar configuração:', error.message);
        }
    }

    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChangeLogo = (valor) => {
        const file = valor.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                Swal.fire('Erro!', 'O arquivo selecionado é muito grande. O tamanho máximo permitido é 2 MB.', 'error');
                return;
            }
            setConfig(prevState => ({
                ...prevState,
                configuracaoRestaurante: {
                    ...prevState.configuracaoRestaurante,
                    urlLogo: file
                }
            }));
        }
    }

    const renderImage = () => {
        if (typeof config.configuracaoRestaurante.urlLogo === 'string') {
            // Considera como URL
            return (
                <img
                    src={config.configuracaoRestaurante.urlLogo}
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={handleClick}
                />
            );
        } else if (config.configuracaoRestaurante.urlLogo instanceof Blob || config.configuracaoRestaurante.urlLogo instanceof File) {
            // Considera como Blob ou File
            return (
                <img
                    src={URL.createObjectURL(config.configuracaoRestaurante.urlLogo)}
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={handleClick}
                />
            );
        }
    };

    const handleChangePreco = (campo, valor) => {
        valor = valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setConfig({
            ...config,
            configuracaoRestaurante: {
                ...config.configuracaoRestaurante,
                ['valorFrete']: valor
            }
        });
    };

    const handleChangeDiaFuncionamento = (e, diaSemana) => {
        const { checked } = e.target;

        setConfig((prevConfig) => {
            // Garantindo que `horariosFuncionamento` seja um array
            const horariosFuncionamento = Array.isArray(prevConfig.configuracaoRestaurante.horariosFuncionamento)
                ? [...prevConfig.configuracaoRestaurante.horariosFuncionamento]
                : [];

            if (checked) {
                // Se o dia foi marcado, adicionar horário padrão (ex: 09:00 - 18:00)
                horariosFuncionamento.push({
                    diaSemana: diaSemana,
                    horarioAbertura: "09:00",
                    horarioFechamento: "18:00",
                });
            } else {
                // Se o dia foi desmarcado, remover o horário correspondente
                const index = horariosFuncionamento?.findIndex((horario) => horario.diaSemana === diaSemana);
                if (index !== -1) {
                    horariosFuncionamento?.splice(index, 1);
                }
            }

            return {
                ...prevConfig,
                configuracaoRestaurante: {
                    ...prevConfig.configuracaoRestaurante,
                    horariosFuncionamento: horariosFuncionamento,
                },
            };
        });
    };


    // Função para atualizar o horário de abertura ou fechamento
    const handleChangeHorario = (e, diaSemana, tipo) => {
        const { value } = e.target;

        setConfig((prevConfig) => {
            const horariosFuncionamento = prevConfig.configuracaoRestaurante.horariosFuncionamento?.map((horario) => {
                if (horario.diaSemana === diaSemana) {
                    if (tipo === 'abertura') {
                        return { ...horario, horarioAbertura: value };
                    } else if (tipo === 'fechamento') {
                        return { ...horario, horarioFechamento: value };
                    }
                }
                return horario;
            });

            return {
                ...prevConfig,
                configuracaoRestaurante: {
                    ...prevConfig.configuracaoRestaurante,
                    horariosFuncionamento: horariosFuncionamento,
                },
            };
        });
    };


    return (
        <div className="flex flex-col h-screen p-4">
            <div className='flex flex-col overflow-y-auto'>
                <div className="text-center flex flex-row items-center">
                    {!config.configuracaoRestaurante.urlLogo && (
                        <button
                            className="button-upload rounded-full shadow-lg p-2"
                            onClick={handleClick}
                            style={{ width: '100px', height: '100px' }} // Definindo a largura e altura para torná-lo um círculo
                        >
                            LOGO
                        </button>
                    )}
                    {config.configuracaoRestaurante.urlLogo && (
                        <div className="flex items-center justify-center">
                            {renderImage()}
                        </div>
                    )}
                    <span className="ml-5 text-2xl font-bold text-center">{config.nome}</span>
                    <input
                        type="file"
                        onChange={handleChangeLogo}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 mb-2 font-bold">Status estabelecimento:</label>
                    <input
                        type="checkbox"
                        name="aberto"
                        checked={config.configuracaoRestaurante.aberto}
                        onChange={handleChangeConfiguracao}
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-gray-700">Aberto</span>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 mb-2 font-bold">Tipo de encomenda:</label>

                    <div className="flex items-center gap-4">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="delivery"
                                checked={config.configuracaoRestaurante.delivery}
                                onChange={handleChangeConfiguracao}
                                className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <span className="ml-2 text-gray-700">Delivery</span>
                        </div>

                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="retirada"
                                checked={config.configuracaoRestaurante.retirada}
                                onChange={handleChangeConfiguracao}
                                className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <span className="ml-2 text-gray-700">Retirada</span>
                        </div>
                    </div>

                    {config.configuracaoRestaurante.delivery && (
                        <div className="flex flex-col mt-4">
                            <label className="block text-gray-700 mb-2 font-bold">Tempo de entrega (minutos):</label>
                            <div className="flex gap-4 ">
                                <div>
                                    <label className="block text-gray-600">Tempo Mínimo:</label>
                                    <input
                                        type="number"
                                        name="tempoMinimoEntrega"
                                        value={config.configuracaoRestaurante.tempoMinimoEntrega || ''}
                                        onChange={handleChangeConfiguracao}
                                        className="mt-2 p-2 border rounded-lg "
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-600">Tempo Máximo:</label>
                                    <input
                                        type="number"
                                        name="tempoMaximoEntrega"
                                        value={config.configuracaoRestaurante.tempoMaximoEntrega || ''}
                                        onChange={handleChangeConfiguracao}
                                        className="mt-2 p-2 border rounded-lg"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {config.configuracaoRestaurante.retirada && (
                        <div className="mt-4">
                            <label className="block text-gray-700 mb-2 font-bold">Tempo de preparo para retirada (minutos):</label>
                            <div className="flex gap-4">
                                <div>
                                    <label className="block text-gray-600">Tempo Mínimo:</label>
                                    <input
                                        type="number"
                                        name="tempoMinimoPreparo"
                                        value={config.configuracaoRestaurante.tempoMinimoPreparo || ''}
                                        onChange={handleChangeConfiguracao}
                                        className="mt-2 p-2 border rounded-lg"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-600">Tempo Máximo:</label>
                                    <input
                                        type="number"
                                        name="tempoMaximoPreparo"
                                        value={config.configuracaoRestaurante.tempoMaximoPreparo || ''}
                                        onChange={handleChangeConfiguracao}
                                        className="mt-2 p-2 border rounded-lg"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>


                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Horários de Funcionamento:</label>

                    {diasDaSemana.map((dia) => {
                        const horarioFuncionamento = config.configuracaoRestaurante.horariosFuncionamento?.find(
                            (horario) => horario.diaSemana === dia
                        );

                        const checked = Boolean(horarioFuncionamento);

                        return (
                            <div key={dia} className="flex items-center mb-2">
                                <label className="inline-flex items-center w-52">
                                    <input
                                        type="checkbox"
                                        name={dia}
                                        checked={checked}
                                        onChange={(e) => handleChangeDiaFuncionamento(e, dia)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-gray-700">{diasDaSemanaMapeados[dia]}</span>
                                </label>

                                {checked && (
                                    <div className="flex ml-4 gap-4">
                                        <label>
                                            Abertura:
                                            <input
                                                type="time"
                                                name={`${dia}-abertura`}
                                                value={horarioFuncionamento?.horarioAbertura || '09:00'}
                                                onChange={(e) => handleChangeHorario(e, dia, 'abertura')}
                                                className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-gray-700"
                                            />
                                        </label>
                                        <label>
                                            Fechamento:
                                            <input
                                                type="time"
                                                name={`${dia}-fechamento`}
                                                value={horarioFuncionamento?.horarioFechamento || '18:00'}
                                                onChange={(e) => handleChangeHorario(e, dia, 'fechamento')}
                                                className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-gray-700"
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                {config.configuracaoRestaurante.delivery && (
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold">Valor do Frete:</label>
                        <CurrencyInput
                            options={{
                                precision: 2,
                                style: "currency",
                                locale: Locales["Portuguese (Brazil)"], // Format Type
                                i18nCurrency: Currencies["Brazilian Real"], // Symbol
                            }}
                            placeholder="Preço"
                            value={config.configuracaoRestaurante.valorFrete}
                            onChangeEvent={handleChangePreco}
                            className="mb-4 px-3 py-2 border rounded-lg"
                        />
                    </div>)}
            </div>
            <div>
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className="w-4/12 bg-blue-500 text-white p-2 rounded-md mt-4 hover:bg-blue-600"
                >
                    Salvar
                </button>
            </div>
        </div>

    );
}
