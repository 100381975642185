import React, { useState } from 'react'
import PedidosNovos from './PedidoNovo/PedidosNovos'
import DetalhesPedido from './DetalhesPedido/DetalhesPedido'
import { useQuery } from '@tanstack/react-query';
import BackendService from '../integrations/BackendService'

export default function Painel() {
  const backendService = new BackendService();
  const [detalhesPedido, setDetalhesPedido] = useState(null);
  const [isAutoPrintEnabled, setIsAutoPrintEnabled] = useState(false);
  const [pedidosParaImprimir, setPedidosParaImprimir] = useState()

  const handlePedidoItemClick = (pedido) => {
    setDetalhesPedido(pedido);
  };

  const handleImprimirSozinho = (pedidosParaAprovar) => {
    setPedidosParaImprimir(pedidosParaAprovar)
  };

  const { data: pedidos, isLoading: isLoadingPedidos, error: errorPedidos, refetch } = useQuery({
    queryKey: ['buscarPedidos'],
    queryFn: async () => {
      const response = await backendService.getPedidos();
      return response; // Retorna os pedidos para uso posterior
    },
  });

  return (
    <div className='flex h-screen  p-4 overflow-y-auto '>
      <PedidosNovos onPedidoItemClick={handlePedidoItemClick} handleImprimirSozinho={handleImprimirSozinho} isAutoPrintEnabled={isAutoPrintEnabled} setIsAutoPrintEnabled={setIsAutoPrintEnabled} pedidos={pedidos} refetch={refetch} />
      <DetalhesPedido pedido={detalhesPedido} setPedido={setDetalhesPedido} isAutoPrintEnabled={isAutoPrintEnabled} pedidosParaImprimir={pedidosParaImprimir} refetch={refetch} />
    </div>
  )
}
