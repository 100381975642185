import axios from "axios";
import Global from "../Global";

class BackendService {
	constructor() {
		this.api = axios.create({
			baseURL: Global.backendUrl,
			timeout: 15000, // tempo limite de 15 segundos para a requisição
		});
	}

	async login(username, password) {
		try {
			const response = await this.api.get(
				"/usuario/autenticar/proprietario",
				{
					params: {
						email: username,
						senha: password,
					},
				}
			);
			return response.data;
		} catch (error) {
			const errorMessage = error.response?.data?.message || "Erro ao tentar autenticar usuário";
			throw new Error(errorMessage);
		}
	}

	async cadastrarRestaurante(restaurante) {
		try {
			const response = await this.api.post(
				"usuario/proprietario",
				restaurante
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar autenticar usuário");
		}
	}

	async getPedidos(token) {
		try {
			const response = await this.api.get(
				"pedido/buscar-pedidos-restaurante",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao obter os pedidos do backend");
		}
	}

	async cancelarPedido(id, data) {
		try {
			const response = await this.api.patch(
				`pedido/cancelar-pedido/${id}`,
				data,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar cancelar pedido");
		}
	}

	async confirmarPedido(id) {
		try {
			const response = await this.api.patch(
				`pedido/confirmar-pedido/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar confirmar pedido");
		}
	}

	async despacharPedido(id) {
		try {
			const response = await this.api.patch(
				`pedido/despachar-pedido/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar confirmar pedido");
		}
	}

	async finalizarPedido(id) {
		try {
			const response = await this.api.patch(
				`pedido/finalizar-pedido/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar confirmar pedido");
		}
	}

	async criarCategoria(nomeCategoria) {
		try {
			const response = await this.api.post(`categoria-prato`, null, {
				params: {
					categoria: nomeCategoria,
				},
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar criar categoria");
		}
	}

	async buscarCardapioParaRestaurante() {
		try {
			const response = await this.api.get("categoria-prato", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async ativarPrato(id) {
		try {
			const response = await this.api.patch(
				`/pratos/ativar-prato/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async desativarPrato(id) {
		try {
			const response = await this.api.patch(
				`/pratos/desativar-prato/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async removerDestacarPrato(id) {
		try {
			const response = await this.api.patch(
				`/pratos/desativar-destaque-prato/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async destacarPrato(id) {
		try {
			const response = await this.api.patch(
				`/pratos/ativar-destaque-prato/${id}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async criarPrato(prato) {
		try {
			const response = await this.api.post(`pratos`, prato, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar criar prato");
		}
	}

	async deletarPrato(id) {
		try {
			const response = await this.api.delete(`/pratos/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar deletar prato");
		}
	}

	async deletarCategoria(id) {
		try {
			const response = await this.api.delete(`/categoria-prato/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar deletar categoria");
		}
	}

	async buscarAdicionaisRestaurante() {
		try {
			const response = await this.api.get("adicionais", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar adicionais");
		}
	}

	async criarAdicional(adicional) {
		try {
			const response = await this.api.post(`adicionais`, adicional, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar criar adicional");
		}
	}

	async editarAdicional(adicional) {
		try {
			const response = await this.api.put(`adicionais`, adicional, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar editar adicional");
		}
	}

	async deletarAdicional(id) {
		try {
			const response = await this.api.delete(`/adicionais/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar deletar adicional");
		}
	}

	async buscarConfiguracaoRestaurante() {
		try {
			const response = await this.api.get("restaurantes/configuracao", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async salvarConfiguracaoRestaurante(config) {
		try {
			const response = await this.api.post(
				"restaurantes/configuracao",
				config,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async buscarPedidosRestauranteHistorico() {
		try {
			const response = await this.api.get(
				"pedido/buscar-pedidos-restaurante/historico",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar pesdidos do restaurante");
		}
	}

	async editarPrato(prato) {
		try {
			const response = await this.api.put(`pratos`, prato, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar editar adicional");
		}
	}

	async buscarCupons() {
		try {
			const response = await this.api.get("cupom", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			console.error("Erro ao tentar buscar cupons", error);
			throw new Error("Erro ao tentar buscar cupons");
		}
	}

	async criarCupom(cupom) {
		try {
			const response = await this.api.post("cupom/gerar", cupom, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async criarCategoriaAdicional(categoriaAdicional) {
		try {
			const response = await this.api.post(
				"/categoria-adicional",
				categoriaAdicional,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async editarAdicionaisEmCategoria(categoriaId, adicionalIds) {
		try {
			var idAdicionais = adicionalIds.join(",");
			const response = await this.api.put(
				`/categoria-adicional/${categoriaId}/adicional?idAdicionais=${idAdicionais}`,
				"",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async editarCategoriaAdicional(categoriaId, categoria) {
		try {
			const response = await this.api.put(
				`/categoria-adicional/${categoriaId}`,
				categoria,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async excluirCategoriaAdicional(categoriaId) {
		try {
			const response = await this.api.delete(
				`/categoria-adicional/${categoriaId}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar buscar cardapio");
		}
	}

	async ordenarCategoria(categoriaOrdenada) {
		try {
			const response = await this.api.post(`categoria-prato/ordenar`, categoriaOrdenada, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error("Erro ao tentar ordenar categoria");
		}
	}
}

export default BackendService;
