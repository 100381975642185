import React, { createContext, useContext, useState } from 'react';
import BackendService from '../integrations/BackendService';
import Swal from 'sweetalert2';


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const backendService = new BackendService();
    const [token, setToken] = useState(localStorage.getItem('token') || null);


    const login = async (username, password) => {
        try {
            const response = await backendService.login(username, password);

            // Tratamento de sucesso
            setToken(response.data.token);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('restaurante', response.data.restaurante);
            return true;
        } catch (error) {
            const errorMessage = error.message || "Ocorreu um erro inesperado. Por favor, tente novamente."; // Mensagem padrão
            Swal.fire(
                'Erro!',
                errorMessage, // Mostra a mensagem de erro capturada ou a mensagem padrão
                'error'
            );
            return false;
        }
    };

    const logout = () => {
        setToken(null);
        localStorage.removeItem('token');
    };

    const register = async (cadastro) => {
        try {
            await backendService.cadastrarRestaurante(cadastro);

            Swal.fire(
                'Resgistrado!',
                'Seu restaurante foi cadastrado com sucesso.',
                'success'
            );

            return true;

        } catch (error) {
            // Tratamento de erro
            console.error('Error:', error);
            Swal.fire(
                'Erro!',
                'Não foi possível cadastrar seu Restaurante.',
                'error'
            );
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{ token, login, logout, register }}>
            {children}
        </AuthContext.Provider>
    );
};