import React from "react";

export default function Item({ item }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				borderBottom: "1px solid #D1D5DB",
				alignItems: "center",
				marginBottom: "24px",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<span>
						{item.quantidade}x {item.nome}
					</span>
					<span>R$ {item.precoTotal.toFixed(2)}</span>
				</div>

				{/* Exibir Adicionais se não estiver vazio */}
				{item.adicional && item.adicional.trim() !== "" && (
					<div style={{ margin: '5px 0', fontSize: '0.9em' }}>
						{(item.adicional.includes('#') ? item.adicional.split('#') : item.adicional.split(','))
							.map((adicional, index) => (
								<p key={index} style={{ margin: 0 }}>{adicional.trim()}</p>
							))}
					</div>
				)}

				{/* Exibir Observação se não estiver vazio */}
				{item.observacao && item.observacao.trim() !== "" && (
					<span style={{ fontSize: "0.875rem" }}>
						Observação: {item.observacao}
					</span>
				)}
			</div>
		</div>
	);
}
