import React, { useState } from 'react'
import { FaPlus } from "react-icons/fa";
import { BsList } from "react-icons/bs";
import Categoria from './Categoria/Categoria';
import BackendService from '../integrations/BackendService';
import {
  CurrencyInput,
  Currencies,
  Locales
} from 'input-currency-react';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';


export default function Cardapio() {
  const backendService = new BackendService();

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [listaCategoriaOrdenar, setListaCategoriaOrdenar] = useState([]);
  const [modalAdicionarAdicional, setModalAdicionarAdicional] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [novoAdicional, setNovoAdicional] = useState({ nome: '', preco: 0.0 });
  const [itemAtual, setItemAtual] = useState({ nome: '', preco: 0.0 });
  const [termoBusca, setTermoBusca] = useState('');
  const [activeTab, setActiveTab] = useState('tela1');
  const [modalAberto, setModalAberto] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [nomeCategoria, setNomeCategoria] = useState("");

  const { data: listaCategoria, isLoading: isLoadingCategoria, error: errorCategoria } = useQuery({
    queryKey: ['buscarCardapioParaRestaurante'],
    queryFn: async () => {
      const response = await backendService.buscarCardapioParaRestaurante();
      setListaCategoriaOrdenar(response)
      return response
    }
  });

  const { data: listaAdicionais, isLoading: isLoadingAdicionais, error: errorAdicionais } = useQuery({
    queryKey: ['buscarAdicionaisRestaurante'],
    queryFn: async () => {
      const response = await backendService.buscarAdicionaisRestaurante();
      return response;
    }
  });

  const itensUtilizandoAdicional = (adicional) => {
    const itensUtilizados = [];

    for (const categoria of listaCategoria) {
      for (const prato of categoria.pratos) {
        if (prato.categoriaAdicionais.length > 0) {
          for (const categoriaAdicional of prato.categoriaAdicionais) {
            const adicionais = categoriaAdicional.adicionais;
            if (adicionais.some(item => item.id === adicional.id)) {
              itensUtilizados.push(prato.nome); // Adiciona o nome do prato
            }
          }
        }
      }
    }

    return itensUtilizados;
  };

  // Função para atualizar o estado do termo de busca
  const handleBusca = (event) => {
    setTermoBusca(event.target.value);
  };
  // Filtra os dados com base no termo de busca
  const dadosFiltrados = listaAdicionais?.filter(item => {
    const termoBuscaMinusculo = termoBusca.toLowerCase();
    return item.nome.toLowerCase().startsWith(termoBuscaMinusculo);
  });

  const abrirModalAdicionarCategoria = () => {
    setModalAberto(true);
    setMensagemErro('');
    // Define o estado do modal como aberto ao clicar no botão
  };
  const fecharModalAdicionarCategoria = () => {
    setModalAberto(false); // Define o estado do modal como fechado
    setNomeCategoria("")
  };
  const handleChangeNomeCategoria = (event) => {
    setNomeCategoria(event.target.value);
  };

  const adicionarCategoria = async () => {
    if (nomeCategoria.trim() !== '') {
      try {
        await backendService.criarCategoria(nomeCategoria);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } catch (error) {
        console.error(error.message);
      }
      setNomeCategoria("")
      fecharModalAdicionarCategoria();
    } else {
      setMensagemErro('Um nome para sua nova categoria é obrigatório.');
    }
  };

  const handleExcluirCategoria = (categoriaId) => {
    // Assume that the backend deletion already happened elsewhere
    queryClient.setQueryData(['buscarCardapioParaRestaurante'], (oldData) => {
      return oldData.filter(categoria => categoria.id !== categoriaId);
    });
  };

  const handleAdicionarAdicional = async () => {

    if (!novoAdicional.nome.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Nome inválido',
        text: 'O campo nome não pode estar vazio.',
      });
      return;
    }

    // Verifica se o preço é maior que 0
    if (novoAdicional.preco < 0) {
      Swal.fire({
        icon: 'error',
        title: 'Preço inválido',
        text: 'O preço não pode ser negativo.',
      });
      return;
    }

    // Verifica se o adicional já existe na lista
    const adicionalExistente = listaAdicionais.some(
      (adicional) =>
        adicional.nome.toLowerCase() === novoAdicional.nome.toLowerCase() &&
        adicional.preco === novoAdicional.preco
    );

    if (adicionalExistente) {
      Swal.fire({
        icon: 'error',
        title: 'Adicional já existe',
        text: 'O adicional que você está tentando adicionar já está na lista.',
      });
      return; // Não prossegue com a adição
    }
    try {

      await backendService.criarAdicional(novoAdicional);
      queryClient.invalidateQueries('buscarAdicionaisRestaurante');
    } catch (error) {
      console.error(error.message);
    }
    setModalAdicionarAdicional(false);
    setNovoAdicional({ nome: '', preco: 0.0 });
  };

  const handleChangePreco = (campo, valor) => {
    const numericValue = parseFloat(valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.'));
    setNovoAdicional({ ...novoAdicional, preco: isNaN(numericValue) ? 0 : numericValue })
  };
  const handleChangePrecoEditar = (campo, valor) => {
    if (valor) {
      // Remove espaços, símbolo de moeda e formatação para converter para número
      const numericValue = parseFloat(valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.'));
      setItemAtual({ ...itemAtual, preco: isNaN(numericValue) ? 0 : numericValue });
    } else {
      setItemAtual({ ...itemAtual, preco: 0 });
    }
  };

  const openEditModal = (item) => {
    setItemAtual(item);
    setEditModalVisible(true);
  };

  const handleEditAdicional = async (item) => {
    try {
      await backendService.editarAdicional(item);
      queryClient.invalidateQueries('buscarAdicionaisRestaurante');
      Swal.fire(
        'Adicional alterado!',
        'O item foi editado.',
        'success'
      );
    } catch (error) {
      console.error(error.message);
      Swal.fire(
        'Erro!',
        'Não foi possível alterar o adicional.',
        'error'
      );
    }
    setEditModalVisible(false)
  };

  const confirmDelete = (item) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: "Você não poderá reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, deletar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAdicional(item);

      }
    });
  };

  const handleDeleteAdicional = async (item) => {
    try {
      await backendService.deletarAdicional(item.id);
      queryClient.invalidateQueries('buscarAdicionaisRestaurante');
      Swal.fire(
        'Deletado!',
        'O item foi deletado.',
        'success'
      );
      setEditModalVisible(false)
    } catch (error) {
      console.error(error.message);
      Swal.fire(
        'Erro!',
        'Não foi possível deletar o adicional.',
        'error'
      );
    }
  };

  const handleCloseOrdenar = () => {
    setOpen(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Atualize a ordem dos itens
    return result.map((item, index) => ({
      ...item,
      ordem: index // Atualiza o campo ordem para refletir a nova posição
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = reorder(listaCategoriaOrdenar, result.source.index, result.destination.index);
    setListaCategoriaOrdenar(updatedItems);
  };

  const handleSalvar = async () => {
    // Cria uma nova lista contendo apenas os campos id e ordem
    const categoriaOrdenada = listaCategoriaOrdenar.map(({ id, ordem }) => ({
      id,
      ordem
    }));
    try {
      Swal.fire({
        title: 'Ordenando...',
        text: 'Por favor, aguarde enquanto ordenamos seu cardápio.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      await backendService.ordenarCategoria(categoriaOrdenada);
      Swal.fire({
        title: "Ordenado!",
        text: "Categoria ordenada com sucesso.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
          window.location.reload();
        }
      });

    } catch (error) {
      console.error(error.message);
      Swal.fire(
        'Erro!',
        'Não foi ordenar sua lista de categoria.',
        'error'
      );
    }
  };

  return (
    <div className='flex flex-col py-4'>
      <div className="flex space-x-2 border-b-2 border-gray-300 mb-4">
        <button
          onClick={() => {
            setActiveTab('tela1');
          }}
          className={`py-2 px-4 rounded-t-lg transition-colors duration-300 ${activeTab === 'tela1'
            ? 'bg-blue-500 text-white border-blue-500'
            : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
            }`}
        >
          Cardápio
        </button>
        <button
          onClick={() => setActiveTab('tela2')}
          className={`py-2 px-4 rounded-t-lg transition-colors duration-300 ${activeTab === 'tela2'
            ? 'bg-blue-500 text-white border-blue-500'
            : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
            }`}
        >
          Adicionais
        </button>
      </div>
      {activeTab === 'tela1' && (
        <div className='flex flex-col p-4 max-h-[90vh] overflow-y-auto'>
          <div className='flex'>
            <button className="flex items-center bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2" onClick={abrirModalAdicionarCategoria}>
              <FaPlus /> <span className='ml-2'>Adicionar Categoria</span>
            </button>
            <button className="flex items-center bg-blue-500 text-white font-bold py-2 px-4 rounded mr-2" onClick={() => setOpen(true)}>
              <BsList size={20} /> <span className='ml-2'>Ordenar categoria</span>
            </button>
          </div>

          <div>
            {listaCategoria?.map((categoria, index) => (
              <Categoria key={index} categoria={categoria} onExcluir={handleExcluirCategoria} listaAdicionais={listaAdicionais} />
            ))}
          </div>

          {modalAberto && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="flex flex-col items-center justify-center bg-white p-4 rounded-md">
                <h2 className="text-lg font-semibold mb-4">Adicionar Categoria</h2>
                {/* Conteúdo do formulário para adicionar categoria */}
                <input
                  type="text"
                  value={nomeCategoria}
                  onChange={handleChangeNomeCategoria}
                  className="border border-gray-300 rounded-md p-2 mb-4 w-full"
                  placeholder="Nome da categoria"
                />
                {mensagemErro && <p className="text-red-600 mb-2">{mensagemErro}</p>}
                <div className='flex w-full justify-between'>
                  <button className="flex-1 mr-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={fecharModalAdicionarCategoria}>
                    Fechar
                  </button>
                  <button className="flex-1 ml-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" onClick={adicionarCategoria}>
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      )}
      {activeTab === 'tela2' && (
        <div className="p-4 m-4 bg-white shadow-md rounded-lg">
          <div className='flex flex-col'>
            <input
              type="text"
              placeholder="Buscar por nome"
              value={termoBusca}
              onChange={handleBusca}
              className="w-64 px-4 py-3 border border-gray-300 rounded-lg mb-6 shadow-sm focus:ring focus:ring-blue-300"
            />

            <button
              className="w-64 mb-6 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
              onClick={() => setModalAdicionarAdicional(true)}
            >
              Criar adicional
            </button>
          </div>

          <div className="max-h-[60vh] overflow-y-auto rounded-lg shadow">
            <table className="min-w-full border border-gray-200 rounded-lg">
              <thead className="bg-blue-500 text-white rounded-t-lg">
                <tr>
                  <th className="py-4 px-6 text-left">Nome</th>
                  <th className="py-4 px-6 text-left">Preço</th>
                  <th className="py-4 px-6 text-left">Utilizado em</th> {/* Nova coluna */}
                  <th className="py-4 px-6 text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                {dadosFiltrados.map((item, index) => (
                  <tr
                    key={index}
                    className={`bg-white hover:bg-blue-50 border-b transition duration-150 ${index % 2 === 0 ? 'bg-gray-50' : ''}`}
                  >
                    <td className="py-4 px-6  border-gray-200">{item.nome}</td>
                    <td className="py-4 px-6  border-gray-200">R$ {item.preco.toFixed(2)}</td>
                    <td className="py-4 px-6  border-gray-200">
                      {itensUtilizandoAdicional(item).length > 0
                        ? itensUtilizandoAdicional(item).join('  --  ')
                        :  <strong>Não utilizado</strong>}
                    </td>
                    <td className="flex p-2  border-gray-200 ">
                      <button
                        onClick={() => openEditModal(item)}
                        className="px-4 py-2 bg-yellow-500 text-white rounded-md shadow-sm hover:bg-yellow-600 mr-2 transition duration-200"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => confirmDelete(item)}
                        className="px-4 py-2 bg-red-500 text-white rounded-md shadow-sm hover:bg-red-600 transition duration-200"
                      >
                        Deletar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      )}


      <Dialog open={open} onClose={handleCloseOrdenar} maxWidth="xs" fullWidth>
        <DialogTitle>Ordenar Categoria</DialogTitle>
        <DialogContent>
          <div className='flex flex-col'>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable' direction='vertical'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='flex flex-col gap-2'
                  >
                    {listaCategoriaOrdenar.map((item, index) => (
                      <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className='flex rounded-md bg-slate-100 border p-2 items-center'
                          >
                            <BsList size={20} /><span className='font-bold ml-2'>{item.nome}</span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrdenar} >
            Fechar
          </Button>
          <Button onClick={handleSalvar} variant="contained" >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {modalAdicionarAdicional && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Adicionar Novo Item</h2>
            <input
              type="text"
              placeholder="Nome"
              value={novoAdicional.nome}
              onChange={(e) => {
                const { value } = e.target;
                // Verificar se o valor contém apenas letras (sem números)
                setNovoAdicional({ ...novoAdicional, nome: value });
              }}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <CurrencyInput options={{
              precision: 2,
              style: "currency",
              locale: Locales["Portuguese (Brazil)"], // Format Type
              i18nCurrency: Currencies["Brazilian Real"] // Symbol
            }}
              type="number"
              placeholder="Preço"
              value={novoAdicional.preco.toFixed(2)}
              onChangeEvent={handleChangePreco}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
                onClick={() => {
                  setModalAdicionarAdicional(false);
                  setNovoAdicional({ nome: '', preco: 0.0 });
                }}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleAdicionarAdicional}
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      )}

      {editModalVisible && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Editar Item</h2>
            <input
              type="text"
              placeholder="Nome"
              value={itemAtual.nome}
              onChange={(e) => setItemAtual({ ...itemAtual, nome: e.target.value })}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <CurrencyInput options={{
              precision: 2,
              style: "currency",
              locale: Locales["Portuguese (Brazil)"], // Format Type
              i18nCurrency: Currencies["Brazilian Real"] // Symbol
            }}
              type="number"
              placeholder="Preço"
              value={itemAtual.preco.toFixed(2)}
              onChangeEvent={handleChangePrecoEditar}
              className="w-full px-4 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
                onClick={() => setEditModalVisible(false)}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={() => handleEditAdicional(itemAtual)}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}

    </div>

  )
}
